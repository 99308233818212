let IsProduction = false;
let CyberSource_URL = "";
let CyberSource_Token_Create_URL = "";

let API_VERSION = "1.0";
let LOGIN_URL = 'URLMissing';
let BACKEND_URL = 'URLMissing';
let UM_LOGIN_URL = "URLMissing";

let Google_Recapture_KEY = "URLMissing"
let NOTIFICATION_HUB_URL = 'URLMissing';

let CART_ITEM_HANDLE_URI = 'URLMissing';
let CART_ITEM_FUNCTIONS_URL = 'URLMissing';

let IsPromotionSubDomain = false;
let PromotionEnvironment = '';

let GoogleMapReactKey = '';

let DefaulProductImage = "";

const hostname = window && window.location && window.location.hostname;

switch (hostname) {
        case 'www.keellssuper.com'://Keells Online LIVE
                BACKEND_URL = 'https://zebraliveback.keellssuper.com';
                LOGIN_URL = "https://www.keellssuper.com";
                CART_ITEM_FUNCTIONS_URL = 'https://jmsl-keellsonline-datasync.azurewebsites.net';
                CART_ITEM_HANDLE_URI = "/api/FunctionHandleCartItem?code=dwISllokfXCeZhiS9ubKQHQyhYIHCvlfvCDaAdYPxV5qC6eVfCUksQ==";
                NOTIFICATION_HUB_URL = "https://jmsl-keellsonline-live-backend-notification-hub.azurewebsites.net/notificationhub";
                CyberSource_URL = "https://secureacceptance.cybersource.com/pay";
                CyberSource_Token_Create_URL = "https://secureacceptance.cybersource.com/pay";
                Google_Recapture_KEY = "6LekXqsZAAAAAPfCMA2ES1zS3MIfS7pKdvQaHmvJ"
                IsProduction = true;
                IsPromotionSubDomain = false;
                GoogleMapReactKey = 'AIzaSyDYDlwFSuWr2npCMlfLB4BHE9nqH7QD7gE';
                DefaulProductImage = "https://essstr.blob.core.windows.net/essimg/Small/Default.jpg";
                break;
        case 'keellssuper.com'://Keells Online LIVE
                BACKEND_URL = 'https://zebraliveback.keellssuper.com';
                LOGIN_URL = "https://www.keellssuper.com";
                CART_ITEM_FUNCTIONS_URL = 'https://jmsl-keellsonline-datasync.azurewebsites.net';
                CART_ITEM_HANDLE_URI = "/api/FunctionHandleCartItem?code=dwISllokfXCeZhiS9ubKQHQyhYIHCvlfvCDaAdYPxV5qC6eVfCUksQ==";
                NOTIFICATION_HUB_URL = "https://jmsl-keellsonline-live-backend-notification-hub.azurewebsites.net/notificationhub";
                CyberSource_URL = "https://secureacceptance.cybersource.com/pay";
                CyberSource_Token_Create_URL = "https://secureacceptance.cybersource.com/pay";
                Google_Recapture_KEY = "6LekXqsZAAAAAPfCMA2ES1zS3MIfS7pKdvQaHmvJ"
                IsProduction = true;
                IsPromotionSubDomain = false;
                GoogleMapReactKey = 'AIzaSyDYDlwFSuWr2npCMlfLB4BHE9nqH7QD7gE';
                DefaulProductImage = "https://essstr.blob.core.windows.net/essimg/Small/Default.jpg";
                break;
        case 'staginglive.keellssuper.com'://Keells Online LIVE Staging
                // BACKEND_URL = 'https://stagingliveback.keellssuper.com';
                // LOGIN_URL = "https://staginglive.keellssuper.com";
                // CART_ITEM_FUNCTIONS_URL = 'https://jmsl-keellsonline-datasync.azurewebsites.net';
                // CART_ITEM_HANDLE_URI = "/api/FunctionHandleCartItem?code=dwISllokfXCeZhiS9ubKQHQyhYIHCvlfvCDaAdYPxV5qC6eVfCUksQ==";
                // NOTIFICATION_HUB_URL = "https://jmsl-keellsonline-live-backend-notification-hub.azurewebsites.net/notificationhub";
                // CyberSource_URL = "https://secureacceptance.cybersource.com/pay";
                // Google_Recapture_KEY = "6LekXqsZAAAAAPfCMA2ES1zS3MIfS7pKdvQaHmvJ"
                // IsProduction = true;
                BACKEND_URL = 'https://zebraliveback.keellssuper.com';
                LOGIN_URL = "https://staginglive.keellssuper.com";
                CART_ITEM_FUNCTIONS_URL = 'https://jmsl-keellsonline-datasync.azurewebsites.net';
                CART_ITEM_HANDLE_URI = "/api/FunctionHandleCartItem?code=dwISllokfXCeZhiS9ubKQHQyhYIHCvlfvCDaAdYPxV5qC6eVfCUksQ==";
                NOTIFICATION_HUB_URL = "https://jmsl-keellsonline-live-backend-notification-hub.azurewebsites.net/notificationhub";
                CyberSource_URL = "https://secureacceptance.cybersource.com/pay";
                CyberSource_Token_Create_URL = "https://secureacceptance.cybersource.com/token/create";
                Google_Recapture_KEY = "6LekXqsZAAAAAPfCMA2ES1zS3MIfS7pKdvQaHmvJ"
                IsProduction = true;
                IsPromotionSubDomain = false;
                GoogleMapReactKey = 'AIzaSyDYDlwFSuWr2npCMlfLB4BHE9nqH7QD7gE';
                DefaulProductImage = "https://essstr.blob.core.windows.net/essimg/Small/Default.jpg";
                break;
        case 'zebra.keellssuper.com'://Keells Online QA
                BACKEND_URL = 'https://zebraback.keellssuper.com';
                LOGIN_URL = "https://zebra.keellssuper.com";
                CART_ITEM_FUNCTIONS_URL = 'https://jmsl-keellsonline-datasyncqa.azurewebsites.net';
                CART_ITEM_HANDLE_URI = '/api/FunctionHandleCartItem?code=PHLulne7lrCM502UaiD18ociVhmS5sae27cubEtsFX0CT3z5ccqqjg==';
                NOTIFICATION_HUB_URL = "https://jmsl-keellsonline-qa-backend-notification-hub.azurewebsites.net/notificationhub";
                CyberSource_URL = "https://testsecureacceptance.cybersource.com/pay";
                // CyberSource_Token_Create_URL = "https://testsecureacceptance.cybersource.com/token/create";
                CyberSource_Token_Create_URL = "https://testsecureacceptance.cybersource.com/pay";
                Google_Recapture_KEY = "6LekXqsZAAAAAPfCMA2ES1zS3MIfS7pKdvQaHmvJ"
                IsProduction = true;
                IsPromotionSubDomain = false;
                DefaulProductImage = "https://essstr.blob.core.windows.net/essimg/Small/Default.jpg";
                break;
        case 'tiger.keellssuper.com'://Keells Online QA
                BACKEND_URL = 'https://zebraback.keellssuper.com';
                LOGIN_URL = "https://tiger.keellssuper.com";
                CART_ITEM_FUNCTIONS_URL = 'https://jmsl-keellsonline-datasyncqa.azurewebsites.net';
                CART_ITEM_HANDLE_URI = '/api/FunctionHandleCartItem?code=PHLulne7lrCM502UaiD18ociVhmS5sae27cubEtsFX0CT3z5ccqqjg==';
                NOTIFICATION_HUB_URL = "https://jmsl-keellsonline-qa-backend-notification-hub.azurewebsites.net/notificationhub";
                CyberSource_URL = "https://testsecureacceptance.cybersource.com/pay";
                // CyberSource_Token_Create_URL = "https://testsecureacceptance.cybersource.com/token/create";
                CyberSource_Token_Create_URL = "https://testsecureacceptance.cybersource.com/pay";
                Google_Recapture_KEY = "6LekXqsZAAAAAPfCMA2ES1zS3MIfS7pKdvQaHmvJ"
                IsProduction = true;
                IsPromotionSubDomain = false;
                GoogleMapReactKey = 'AIzaSyDYDlwFSuWr2npCMlfLB4BHE9nqH7QD7gE';
                DefaulProductImage = "https://essstr.blob.core.windows.net/essimg/Small/Default.jpg";
                break;
        case 'stagingzebra.keellssuper.com'://Keells Online QA Staging
                BACKEND_URL = 'https://stagingzebraback.keellssuper.com';
                LOGIN_URL = "https://stagingzebra.keellssuper.com";
                CART_ITEM_FUNCTIONS_URL = 'https://jmsl-keellsonline-datasyncqa.azurewebsites.net';
                CART_ITEM_HANDLE_URI = '/api/FunctionHandleCartItem?code=PHLulne7lrCM502UaiD18ociVhmS5sae27cubEtsFX0CT3z5ccqqjg==';
                NOTIFICATION_HUB_URL = "https://jmsl-keellsonline-qa-backend-notification-hub.azurewebsites.net/notificationhub";
                CyberSource_URL = "https://secureacceptance.cybersource.com/pay";
                // CyberSource_Token_Create_URL = "https://secureacceptance.cybersource.com/token/create";
                CyberSource_Token_Create_URL = "https://secureacceptance.cybersource.com/pay";
                Google_Recapture_KEY = "6LekXqsZAAAAAPfCMA2ES1zS3MIfS7pKdvQaHmvJ"
                IsProduction = true;
                IsPromotionSubDomain = false;
                GoogleMapReactKey = 'AIzaSyDYDlwFSuWr2npCMlfLB4BHE9nqH7QD7gE';
                DefaulProductImage = "https://essstr.blob.core.windows.net/essimg/Small/Default.jpg";
                break;
        case 'hamperqa.keellssuper.com'://Keells Hamper QA
                BACKEND_URL = '';
                LOGIN_URL = "https://zebra.keellssuper.com";
                CART_ITEM_FUNCTIONS_URL = '';
                CART_ITEM_HANDLE_URI = '';
                NOTIFICATION_HUB_URL = "";
                CyberSource_URL = "";
                Google_Recapture_KEY = "6LekXqsZAAAAAPfCMA2ES1zS3MIfS7pKdvQaHmvJ"
                IsProduction = true;
                IsPromotionSubDomain = true;
                PromotionEnvironment = 'QA'
                break;
        case 'hamper.keellssuper.com'://Keells Hamper LIVE
                BACKEND_URL = '';
                LOGIN_URL = "https://www.keellssuper.com";
                CART_ITEM_FUNCTIONS_URL = '';
                CART_ITEM_HANDLE_URI = '';
                NOTIFICATION_HUB_URL = "";
                CyberSource_URL = "";
                Google_Recapture_KEY = "6LekXqsZAAAAAPfCMA2ES1zS3MIfS7pKdvQaHmvJ"
                IsProduction = true;
                IsPromotionSubDomain = true;
                PromotionEnvironment = 'LIVE'
                break;
        case 'jmsl-keellsonline-qa-special-hamper.azurewebsites.net'://Keells Special Hamper QA
                BACKEND_URL = '';
                LOGIN_URL = "https://zebra.keellssuper.com";
                CART_ITEM_FUNCTIONS_URL = '';
                CART_ITEM_HANDLE_URI = '';
                NOTIFICATION_HUB_URL = "";
                CyberSource_URL = "";
                Google_Recapture_KEY = "6LekXqsZAAAAAPfCMA2ES1zS3MIfS7pKdvQaHmvJ"
                IsProduction = true;
                IsPromotionSubDomain = true;
                PromotionEnvironment = 'SPECIAL_HAMPER_QA'
                break;
        case 'seasonalspecials.keellssuper.com'://Keells Special Hamper LIVE
                BACKEND_URL = '';
                LOGIN_URL = "https://www.keellssuper.com";
                CART_ITEM_FUNCTIONS_URL = '';
                CART_ITEM_HANDLE_URI = '';
                NOTIFICATION_HUB_URL = "";
                CyberSource_URL = "";
                Google_Recapture_KEY = "6LekXqsZAAAAAPfCMA2ES1zS3MIfS7pKdvQaHmvJ"
                IsProduction = true;
                IsPromotionSubDomain = true;
                PromotionEnvironment = 'SPECIAL_HAMPER_LIVE'
                break;
        case 'keellslauries.keellssuper.com':
                BACKEND_URL = '';
                LOGIN_URL = "https://www.keellssuper.com";
                CART_ITEM_FUNCTIONS_URL = '';
                CART_ITEM_HANDLE_URI = '';
                NOTIFICATION_HUB_URL = "";
                CyberSource_URL = "";
                Google_Recapture_KEY = "6LekXqsZAAAAAPfCMA2ES1zS3MIfS7pKdvQaHmvJ"
                IsProduction = true;
                IsPromotionSubDomain = true;
                PromotionEnvironment = 'LAURIES'
                break;
        case 'jmsl-keellsonline-live-lauries.azurewebsites.net':
                BACKEND_URL = '';
                LOGIN_URL = "https://www.keellssuper.com";
                CART_ITEM_FUNCTIONS_URL = '';
                CART_ITEM_HANDLE_URI = '';
                NOTIFICATION_HUB_URL = "";
                CyberSource_URL = "";
                Google_Recapture_KEY = "6LekXqsZAAAAAPfCMA2ES1zS3MIfS7pKdvQaHmvJ"
                IsProduction = true;
                IsPromotionSubDomain = true;
                PromotionEnvironment = 'LAURIES'
                break;
        case 'keellskafe.keellssuper.com':
                BACKEND_URL = '';
                LOGIN_URL = "https://www.keellssuper.com";
                CART_ITEM_FUNCTIONS_URL = '';
                CART_ITEM_HANDLE_URI = '';
                NOTIFICATION_HUB_URL = "";
                CyberSource_URL = "";
                Google_Recapture_KEY = "6LekXqsZAAAAAPfCMA2ES1zS3MIfS7pKdvQaHmvJ"
                IsProduction = true;
                IsPromotionSubDomain = true;
                PromotionEnvironment = 'KEELLSKAFE'
                break;
        case 'jmsl-keellsonline-live-iconic.azurewebsites.net':
                BACKEND_URL = '';
                LOGIN_URL = "https://www.keellssuper.com";
                CART_ITEM_FUNCTIONS_URL = '';
                CART_ITEM_HANDLE_URI = '';
                NOTIFICATION_HUB_URL = "";
                CyberSource_URL = "";
                Google_Recapture_KEY = "6LekXqsZAAAAAPfCMA2ES1zS3MIfS7pKdvQaHmvJ"
                IsProduction = true;
                IsPromotionSubDomain = true;
                PromotionEnvironment = 'KEELLSKAFE'
                break;
        case 'jmsl-keellsonline-qa-tabloid.azurewebsites.net'://Keells Online Tabloid QA
        case 'tabloidqa.keellssuper.com'://Keells Online Tabloid QA  
                BACKEND_URL = 'https://zebraback.keellssuper.com';
                LOGIN_URL = "https://tiger.keellssuper.com";
                CART_ITEM_FUNCTIONS_URL = '';
                CART_ITEM_HANDLE_URI = '';
                NOTIFICATION_HUB_URL = "";
                CyberSource_URL = "";
                CyberSource_Token_Create_URL = "";
                Google_Recapture_KEY = ""
                IsProduction = true;
                IsPromotionSubDomain = true;
                GoogleMapReactKey = '';
                DefaulProductImage = "";
                PromotionEnvironment = 'TABLOID';
                break;
        case 'jmsl-keellsonline-live-tabloid.azurewebsites.net'://Keells Online Tabloid Live
        case 'tabloid.keellssuper.com'://Keells Online Tabloid Live
                BACKEND_URL = 'https://zebraliveback.keellssuper.com';
                LOGIN_URL = "https://www.keellssuper.com";
                CART_ITEM_FUNCTIONS_URL = '';
                CART_ITEM_HANDLE_URI = '';
                NOTIFICATION_HUB_URL = "";
                CyberSource_URL = "";
                CyberSource_Token_Create_URL = "";
                Google_Recapture_KEY = ""
                IsProduction = true;
                IsPromotionSubDomain = true;
                GoogleMapReactKey = '';
                DefaulProductImage = "";
                PromotionEnvironment = 'TABLOID';
                break;
        case 'jmsl-keellsonline-live-keellsmenu.azurewebsites.net':
        case 'keellsmenu.keellssuper.com':
                BACKEND_URL = '';
                LOGIN_URL = "https://www.keellssuper.com";
                CART_ITEM_FUNCTIONS_URL = '';
                CART_ITEM_HANDLE_URI = '';
                NOTIFICATION_HUB_URL = "";
                CyberSource_URL = "";
                Google_Recapture_KEY = "6LekXqsZAAAAAPfCMA2ES1zS3MIfS7pKdvQaHmvJ"
                IsProduction = true;
                IsPromotionSubDomain = true;
                PromotionEnvironment = 'KEELLSMENU';
                break;
        case 'jmsl-keellsonline-qa-keellsmenu.azurewebsites.net':
                BACKEND_URL = '';
                LOGIN_URL = "https://www.keellssuper.com";
                CART_ITEM_FUNCTIONS_URL = '';
                CART_ITEM_HANDLE_URI = '';
                NOTIFICATION_HUB_URL = "";
                CyberSource_URL = "";
                Google_Recapture_KEY = "6LekXqsZAAAAAPfCMA2ES1zS3MIfS7pKdvQaHmvJ"
                IsProduction = true;
                IsPromotionSubDomain = true;
                PromotionEnvironment = 'KEELLSMENU';
                break;
        case 'tiger1.keellssuper.com'://Keells Online QA 02
                BACKEND_URL = 'https://zebraback1.keellssuper.com';
                LOGIN_URL = "https://tiger1.keellssuper.com";
                CART_ITEM_FUNCTIONS_URL = 'https://jmsl-keellsonline-datasyncqa.azurewebsites.net';
                CART_ITEM_HANDLE_URI = '/api/FunctionHandleCartItem?code=PHLulne7lrCM502UaiD18ociVhmS5sae27cubEtsFX0CT3z5ccqqjg==';
                NOTIFICATION_HUB_URL = "https://jmsl-keellsonline-qa-backend-notification-hub.azurewebsites.net/notificationhub";
                CyberSource_URL = "https://testsecureacceptance.cybersource.com/pay";
                // CyberSource_Token_Create_URL = "https://testsecureacceptance.cybersource.com/token/create";
                CyberSource_Token_Create_URL = "https://testsecureacceptance.cybersource.com/pay";
                Google_Recapture_KEY = "6LekXqsZAAAAAPfCMA2ES1zS3MIfS7pKdvQaHmvJ"
                IsProduction = true;
                IsPromotionSubDomain = false;
                GoogleMapReactKey = 'AIzaSyDYDlwFSuWr2npCMlfLB4BHE9nqH7QD7gE';
                DefaulProductImage = "https://essstr.blob.core.windows.net/essimg/Small/Default.jpg";
                break;
        case 'localhost':
                BACKEND_URL = 'https://localhost:44331';
                LOGIN_URL = "http://localhost:3000";
                CART_ITEM_FUNCTIONS_URL = 'https://jmsl-keellsonline-datasyncqa.azurewebsites.net';
                CART_ITEM_HANDLE_URI = '/api/FunctionHandleCartItem?code=PHLulne7lrCM502UaiD18ociVhmS5sae27cubEtsFX0CT3z5ccqqjg==';
                CyberSource_URL = "https://testsecureacceptance.cybersource.com/pay";
                // CyberSource_Token_Create_URL = "https://testsecureacceptance.cybersource.com/token/create";
                CyberSource_Token_Create_URL = "https://testsecureacceptance.cybersource.com/pay";
                Google_Recapture_KEY = "6LdBv-YUAAAAAMn6SKjn72NgQQnzeWSTLhUj5eVT";
                NOTIFICATION_HUB_URL = "http://localhost:58815/notificationhub";
                IsPromotionSubDomain = false;
                //PromotionEnvironment = 'QA';
                PromotionEnvironment = 'KEELLSKAFE';
                // IsProduction = true;
                GoogleMapReactKey = 'AIzaSyDYDlwFSuWr2npCMlfLB4BHE9nqH7QD7gE';
                DefaulProductImage = "https://essstr.blob.core.windows.net/essimg/Small/Default.jpg";
                break;

        default:
                console.error('Hosted server name is missing in the environment-config file.');
                console.error(hostname);
                break;
}

export {
        BACKEND_URL, LOGIN_URL, UM_LOGIN_URL, IsProduction, CART_ITEM_FUNCTIONS_URL, CART_ITEM_HANDLE_URI,
        CyberSource_URL, Google_Recapture_KEY, NOTIFICATION_HUB_URL, API_VERSION, IsPromotionSubDomain, PromotionEnvironment, CyberSource_Token_Create_URL, GoogleMapReactKey, DefaulProductImage
};