const initialState = {
     promoCodeID: null 
    , promoCodeFormat: ""
    , promoCodeDiscountedAmount: 0.00
    , itemsFinalTotalAmount: 0.00
    , promoCodeFormatValidationMessage: ""
    , promotionHeaderID: 0
};

export const promoCodeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'promoCodes/redeemed':
            return {
                promoCodeID: action.payload.promoCodeID
                , promoCodeFormat: action.payload.promoCodeFormat
                , promoCodeDiscountedAmount: action.payload.promoCodeDiscountedAmount
                , itemsFinalTotalAmount: action.payload.itemsFinalTotalAmount
                , promoCodeFormatValidationMessage: action.payload.promoCodeFormatValidationMessage
                , promotionHeaderID: action.payload.promotionHeaderID
            };
        case 'promoCodes/reset':
            return initialState;
        default:
            return state;
    }
};